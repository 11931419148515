import { useState, useEffect } from "react";
import jwtDecode from 'jwt-decode';
import { isValidToken } from "utils/jwt";
// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Vision UI Dashboard React themes
import theme from "assets/theme";

// Vision UI Dashboard React routes
import routes from "routes";
import NotFound from "layouts/notFound";

import "./App.css";
import RouteGuard from "components/Guard/RouteGuard";
import CheckTokenExpiration from "components/CheckTokenExpiration";

export default function App() {
  const { pathname } = useLocation();

  // const TOKEN_KEY = "token";

  // function checkTokenExpiration() {
  //   const token = localStorage.getItem(TOKEN_KEY);
  //   if (token && isValidToken(token)) {
  //     const { exp } = jwtDecode(token);
  //     const expirationTime = exp * 1000; // convert to milliseconds
  //     const currentTime = Date.now();
  //     const timeUntilExpiration = expirationTime - currentTime;
  //     if (timeUntilExpiration <= 0) {
  //       alert("Your session has expired. Please log in again.");
  //       localStorage.removeItem(TOKEN_KEY);
  //       localStorage.removeItem("user");
  //       // redirect the user to the login page or perform any other necessary actions
  //     } else {
  //       setTimeout(checkTokenExpiration, timeUntilExpiration);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   checkTokenExpiration();
  // }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route && route.auth) {
        return <RouteGuard exact path={route.route} component={route.component} key={route.key} />;
      } else if (route.route && !route.auth) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }
      return <NotFound  />;
    });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CheckTokenExpiration />
      <Switch>
        {getRoutes(routes)}
        {/* "Not Found" route - should be at the end */}
        <Route component={NotFound} />
        {/* <Redirect from="/" to="/home" /> */}
      </Switch>
    </ThemeProvider>
  );
}
