// // RouteGuard.js
import { Route, Redirect } from 'react-router-dom';
import { isValidToken } from 'utils/jwt';

const RouteGuard = ({ component: Component, defaultRedirectPath, ...rest }) => {
  const token = localStorage.getItem('token');
  const isSignInRoute = rest.path === '/sign-in';

  // if (!token || !isValidToken(token)) {
  //   return <Redirect to="/sign-in" />;
  // }

  const currentUrl = window.location.pathname + window.location.search;

  if (!token || !isValidToken(token)) {
    return <Redirect to={`/sign-in?redirect=${encodeURIComponent(currentUrl)}`} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} />
      )}
    />
  );
};

export default RouteGuard;