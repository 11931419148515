import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";
import { WEB_URL } from "config";
import axiosInstance from "utils/axios";
import IneLoginSpinner from "components/IneLoginSpinner";
import { isValidToken } from "utils/jwt";
import useWoopraTracker from "utils/woopraTracker";
import IneSignIn from "./signin";
import IneSignUp from "./signup";
import ForgotPassword from "./forgot-password";
import IneNotification from "components/IneNotification";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../firebase";
import axios from "../../utils/axios";

function SignIn() {
  const { trackWoopraEvent } = useWoopraTracker();
  
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("signin");
  const [notificationMsg, setNotificationMsg] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const redirectUrl = decodeURIComponent(new URLSearchParams(location.search).get("redirect"));
  const tokenState = decodeURIComponent(new URLSearchParams(location.search).get("session"));

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (redirectUrl && token && isValidToken(token)) {
      history.push(redirectUrl); // Redirect to the decoded URL if the user is already signed in
    } else if (token && isValidToken(token)) {
      history.push("/"); // Redirect to home if the user is already signed in
    }
  }, [redirectUrl, history]);

  const handleShowNotification = (value) => {
    //console.log("clicked");
    if (value !== undefined) {
      setShowNotification(value);
    }
  };

  useEffect(() => {
    const initialMsgState = localStorage.getItem("_initialmsg");
    if (tokenState == "c4bfb2a0bab0e91bc7dcfbe3bbec246e") {
      localStorage.clear();
    } 
    if (tokenState == "c4bfb2a0bab0e91bc7dcfbe3bbec246e" && initialMsgState !== "true") {
      setNotificationMsg("Your session has expired. Please sign in again.");
      setNotificationStatus("error");
      setShowNotification(true);
      localStorage.setItem("_initialmsg", "true");
    }
  }, []);

  const login = async (authToken, authType, recaptchaToken = null) => {
    let portalPreference = 'labs'
    if (redirectUrl.includes('/ctf/')) {
      portalPreference = 'ctf'
    }
    setLoading(true);
    axiosInstance
      .post("/verify/auth", { authToken, authType, recaptchaToken, portalPreference })
      .then((res) => {
        //console.log(res.data.user.token);
        localStorage.setItem("token", res.data.user.token);
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: res.data.user.name,
            email: res.data.user.email,
            uid: res.data.user.uid,
          })
        );
        // save to cookie 
        document.cookie = `token=${res.data.user.token}; path=/; max-age=86400; secure; samesite=none`;
        
        axios.defaults.headers.common.Authorization = `Bearer ${res.data.user.token}`;
        if (redirectUrl.includes('/ctf/')) {
          window.location.href = WEB_URL + redirectUrl;
        }
        // check type of redirectUrl
        console.log(res.data.user)
        trackWoopraEvent({
          form_name: 'login',
          name: res.data.user.name,
          email: res.data.user.email,
        });
        console.log('Signed in using google')
        console.log(typeof(redirectUrl));
        console.log(redirectUrl);
        history.push(redirectUrl !== "null" ? redirectUrl : '/'  || "/");
        // setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        setNotificationMsg(err.message);
        setNotificationStatus("error");
        setShowNotification(true);
        // history.push("/home");
      });
  };

  const GoogleLogin = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.idToken;
      login(token, "google");
      //console.log(token);
      // setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const links = [
    {
      href: "https://ine.com/pages/plans",
      target: "_blank",
      rel: "noopener",
      text: "Learn More About Our Plans",
    },
    {
      href: "https://ine.com",
      target: "_blank",
      rel: "noopener",
      text: "INE.com",
    },
    {
      href: "https://community.ine.com",
      target: "_blank",
      rel: "noopener",
      text: "INE Community",
    },
  ];

  return (
    <main>
      <div className="container">
        <section className="auth-form">
          <div className="auth-form__container">
            <div className="authentication-background">
              <div>
                <img
                  src="https://my.ine.com/img/home-logo.7d34f2b1.png"
                  alt="INE logo"
                  className="ine-form__logo"
                />
                <div className="ine-form__additional-links authentication-background__additional-links">
                  {links.map((link) => (
                    <a
                      key={link.href}
                      href={link.href}
                      target={link.target}
                      rel={link.rel}
                      className="ine-form__additional-link"
                    >
                      {link.text}
                    </a>
                  ))}
                </div>
              </div>
            </div>

            {loading ? (
              <IneLoginSpinner />
            ) : (
              <>
                {section === "signin" ? (
                  <IneSignIn
                    history={history}
                    redirectUrl={redirectUrl}
                    setLoading={setLoading}
                    setSection={setSection}
                    links={links}
                    setNotificationMsg={setNotificationMsg}
                    open={handleShowNotification}
                    setStatus={setNotificationStatus}
                    GoogleLogin={GoogleLogin}
                    AuthLogin={login}
                  />
                ) : null}
                {section === "signup" ? (
                  <IneSignUp
                    history={history}
                    redirectUrl={redirectUrl}
                    setLoading={setLoading}
                    setSection={setSection}
                    links={links}
                    setNotificationMsg={setNotificationMsg}
                    open={handleShowNotification}
                    setStatus={setNotificationStatus}
                    GoogleLogin={GoogleLogin}
                  />
                ) : null}
                {section === "forgot-password" ? (
                  <ForgotPassword
                    history={history}
                    redirectUrl={redirectUrl}
                    setLoading={setLoading}
                    setSection={setSection}
                    links={links}
                    setNotificationMsg={setNotificationMsg}
                    open={handleShowNotification}
                    setStatus={setNotificationStatus}
                    GoogleLogin={GoogleLogin}
                  />
                ) : null}
              </>
            )}
            {showNotification ? (
              <IneNotification
                message={notificationMsg}
                status={notificationStatus}
                open={handleShowNotification}
              />
            ) : null}
          </div>
        </section>
      </div>
    </main>
  );
}

export default SignIn;
