

import colors from "assets/theme/base/colors";

const { info, dark } = colors;
export default {
  html: {
    scrollBehavior: "smooth",
    background: dark.body,
  },
  body: {
    background: dark.body,
    backgroundSize: "cover",
  },
  // "*, *::before, *::after": {
  //   margin: 0,
  //   padding: 0,
  // },
  // "a, a:link, a:visited": {
  //   textDecoration: "none !important",
  // },
  // "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
  //   color: `${dark.body} !important`,
  //   transition: "color 150ms ease-in !important",
  // },
  // "a.link:hover, .link:hover, a.link:focus, .link:focus": {
  //   color: `${info.body} !important`,
  // },
};
