import React from "react";
import "./style.css";
function IneLoginSpinner(props) {
  return (
    <>
      <div className="loading-spinner__container auth-form__wrapper auth-form__spinner">
        <div className="loading-spinner-sign">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={180}
            height={180}
            viewBox="-10 -10 220 220"
            fill="none"
            color="#A793F6"
            className="loading-spinner__spinner"
          >
            <defs>
              <linearGradient id="a">
                <stop offset="0%" stopOpacity={0} stopColor="currentColor" />
                <stop offset="100%" stopOpacity=".5" stopColor="currentColor" />
              </linearGradient>
              <linearGradient id="b">
                <stop offset="0%" stopColor="currentColor" />
                <stop offset="100%" stopOpacity=".5" stopColor="currentColor" />
              </linearGradient>
            </defs>
            <g strokeWidth={13}>
              <path stroke="url(#a)" d="M4 100a96 96 0 01192 0" />
              <path stroke="url(#b)" d="M196 100a96 96 0 01-192 0" />
              <path stroke="currentColor" strokeLinecap="round" d="M4 100a96 96 0 010-2" />
            </g>
            <animateTransform
              from="0 0 0"
              to="360 0 0"
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1300ms"
            />
          </svg>
        </div>
        <h3 className="loading-spinner__label">You're being logged in</h3>
      </div>
    </>
  );
}

export default IneLoginSpinner;
