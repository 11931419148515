import { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import "./style.css";

function RunningLab({ labname, labid, user, setRunning, categoryId }) {
  const history = useHistory();
  const [labStatus, setLabStatus] = useState("running");
  const stopLab = () => {
    setLabStatus("stopping");
    axiosInstance
      .post(`/stop-lab`, { userId: user })
      .then((res) => {
       //console.log(res.data);
        if (res.data.status === "success") {
          removeLabLocalState();
          setRunning(false);
        }
      })
      .catch((err) => {
        // setRunning(false);
        setNotificationStatus("error");
        setNotificationMsg(err.message);
        setShowNotification(true);
      });
  };
  const removeLabLocalState = () => {
    localStorage.removeItem("labState");
  };
  return (
    <>
      <div data-v-e773932c="" className="global-session pta-card-session__session-details">
        <div className="global-session__body">
          <div className="global-session__session-running">
            <div className="global-session__session-running__body">
              <p className="global-session__subtitle">Another lab is running</p>
              <p className="global-session__text">
                You can't start this lab until you stop your other running lab.
              </p>
              <p className="global-session__lab-running">Lab running: {labname}</p>
              {/* <p className="global-session__lab-running">00:05:20</p> */}
              <button
                className="btn btn--tertiary btn--default-icon-color btn--tertiary--with-icon  global-session__flat-btn btn-adjst"
                onClick={stopLab}
                disabled={labStatus === "stopping"}
              >
                <span className="global-session__flat-btn-text">STOP LAB</span>
                {labStatus === "running" ? (
                  <svg
                    width={22}
                    height={22}
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                  >
                    <path
                      d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z"
                      stroke="#fff"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 8H8v6h6V8z"
                      stroke="#fff"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : null}
                {labStatus === "stopping" ? (
                  <div className="loading-small">
                    <svg
                      width={22}
                      height={22}
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="loading-small__spinner"
                    >
                      <path
                        d="M11 1v4m0 12v4M3.93 3.93l2.83 2.83m8.48 8.48l2.83 2.83M1 11h4m12 0h4M3.93 18.07l2.83-2.83m8.48-8.48l2.83-2.83"
                        stroke="#fff"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                ) : null}
                {/**/}
              </button>
              {/* <button
                className="pta-card-session__btn btn btn--secondary"
              >
                Go To Lab
              </button> */}
              <a
                  href={`/${categoryId}/lab/${labid}`}
                  id="state-control"
                  disabled={labStatus === "stopping"}
                  className="btn btn--secondary level__btn-sub btn-adjst"
                >
                  Go to Lab
                </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RunningLab;
