import React from "react";
import { useHistory, Link } from "react-router-dom";
import "./style.css";

function IneModal({ category, messageTitle, messageDescription, pricingBtn, homeBtn, checkoutUrl, selfClose, selfCloseCallback }) {
  const history = useHistory();

  const navigateToCategory = (categoryId) => {
    if (selfClose) {
      selfCloseCallback(false);
    }else{
      history.push(`/labs/${categoryId}`);
    }
    
  };
  return (
    <>
      <div data-v-d0d56558="" role="alert" className="dialog-modal">
        <div className="dialog-modal__wrapper">
          <button className="dialog-modal__close" onClick={() => navigateToCategory(category)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="dialog-modal__close--icon feather feather-x"
            >
              <line x1={18} y1={6} x2={6} y2={18} />
              <line x1={6} y1={6} x2={18} y2={18} />
            </svg>
          </button>
          <div data-v-d0d56558="" className="send-issue__form">
            <div className="popup-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M4 22V8h3V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h3v14H4Zm8-5q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Z"
                />
              </svg>
              <h3 className="send-issue__title">{messageTitle}</h3>
              <h6 className="modal_desc">
                {messageDescription}
              </h6>
            </div>
            <div data-v-d0d56558="" className="action-buttons">
              {
                pricingBtn ?  <button
                data-v-d0d56558=""
                //   disabled="disabled"
                onClick={() => window.open(`${checkoutUrl}`, "_blank")}
                type="submit"
                className="btn btn--primary footer__community-button-cta"
              >
                View Pricing
              </button> : null
              }
              
            </div>
            <div data-v-d0d56558="" className="action-buttons">
              {
                homeBtn ?  <Link to="/home" className="btn btn--secondary">
                Return Home
              </Link> : null
              }
              
            </div>
          </div>
          {/**/}
        </div>
      </div>
    </>
  );
}

export default IneModal;
