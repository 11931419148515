import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// Images
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
// Vision UI Dashboard React example components
// Overview page components
import IneHeader from "components/IneHeader";
import axiosInstance from "../../utils/axios";

import IneLabHeading from "components/IneLabHeading";
import IneLabListCard from "components/IneLabListCard";
import IneSpinner from "components/IneSpinner";
import labImage from "../../assets/images/herosection.png";
import "./style.css";
import IneFooter from "components/IneFooter";

function Home() {
  const [loading, setLoading] = useState(true);
  const [labs, setLabs] = useState({});
  const [clickedCardIndex, setClickedCardIndex] = useState(null);

  useEffect(() => {
    axiosInstance
      .get("/data/home")
      .then((res) => {
        //console.log(res.data);
        setLabs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, []);

  const mapLabData = (labData) => {
    const mappedData = Object.entries(labData).map(([key, item]) => (
      <Grid item xs={20} md={20} xl={20} key={key}>
        <IneLabListCard
          image={item.background}
          label={""}
          title={item.title}
          description={item.overview}
          action={{
            type: "internal",
            route: `/labs/${item.id}`,
            color: "white",
            label: "TRY NOW",
          }}
        />
      </Grid>
    ));

    return mappedData;
  };

  const mappedCategories = Object.entries(labs).map(([order, item], index) => (
    <VuiBox display="flex" flexDirection="column" height="100%" key={index} mb={10}>
      <VuiBox display="flex" flexDirection="column">
        <IneLabHeading title={Object.keys(item)[0]} icon={item.icon} />
      </VuiBox>
      <Grid container spacing={3}>
        {mapLabData(Object.values(item)[0])}
      </Grid>
    </VuiBox>
  ));

  return (
    <>
      <IneHeader setLoading={setLoading} />

      {loading ? (
        <IneSpinner data-testid="spinner-component" />
      ) : (
        <div id="__next">
          <div className="layout styles_defaultLayout__S2o19 styles_backgroundDark__4X432">
            <div className="styles_megaNav__p4i82">
              <div className="styles_contentWrapper__Cb6zS styles_megaNavContentWrapper__i3ude">
                <ul className="styles_megaNavItemList__wbwsT" />
                <div className="styles_megaNavSubMenu__385vI" />
              </div>
            </div>
            <main className="main_home__1Z8Zy">
              <img src={labImage} className="bg_img_secondary" alt="Lab Icon" />
              <section className="styles_hero__cVtc5 styles_themeDark__RuaGk">
                <div className="styles_contentWrapper__Cb6zS styles_heroContent__mVpgY">
                  <div className="styles_containerOneColumn__4PQx_">
                    <h1 className="text text--h1 styles_heroTitle__fjWhp">Try Our Labs</h1>
                    <p className="text text--body-i styles_heroDescription__dQDXI">
                      At INE, we help our customers master technical skills in Cybersecurity,
                      Networking, and Cloud by offering high-quality courses and 3100+ no-VPN,
                      browser-based labs. Sign in now and try labs from many of our courses for
                      FREE! No credit card required.
                    </p>
                  </div>
                </div>
              </section>
              <Grid container spacing={3} ml={0}>
                {mappedCategories}
              </Grid>
            </main>
            <IneFooter />
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
