import { Link } from 'react-router-dom';
import './style.css'

function NotFound() {
  return (
    <main>
      <div className="container">
        <section className="missing">
          <div className="missing__spiff-wrapper">
            <img
              src="https://my.ine.com/img/Spiff.884173c6.png"
              alt="Cute character called Spiff floating in space"
              className="missing__spiff"
            />
          </div>
          <div className="missing__error-msg-wrapper">
            <h1 className="missing__error-msg">how did you get way out here?</h1>
            <div className="missing__home-btn-wrapper">
              <Link to="/home" className="missing__home-btn router-link-active">
                return home
              </Link>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default NotFound;
