import React from "react";
function IneSpinner(props) {
  return (
    <>
      <div className="loading-spinner">
        <div className="action-loader">
          <div
            className="vue-loaders line-scale loading-spinner__spinner"
            style={{ transform: "scale(1)" }}
          >
            <div style={{ backgroundColor: "rgb(239, 125, 34)" }} />
            <div style={{ backgroundColor: "rgb(239, 125, 34)" }} />
            <div style={{ backgroundColor: "rgb(239, 125, 34)" }} />
            <div style={{ backgroundColor: "rgb(239, 125, 34)" }} />
            <div style={{ backgroundColor: "rgb(239, 125, 34)" }} />
          </div>
        </div>
      </div>
    </>
  );
}

export default IneSpinner;
