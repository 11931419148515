import React, { useState } from 'react';
import axios from 'axios';
import "./style.css";
function IneFooter(props) {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      return;
    }

    const endpoint = 'https://uaa.ine.com/uaa/track-lead';
    const subscription = {
      "email": `${email}`,
      "source": "website-subscribe",
      "event": "newsletter-subscribe",
      "attrs": [
        {
          "user_alias": {
            "alias_name": "website-subscribe",
            "alias_label": `${email}`
          }
        }
      ]
    }
    setSubscribed(true);
    axios
      .post(endpoint, subscription)
      .then((response) => {
      })
      .catch((error) => {
      });
  };
  return (
    <>
      <footer role="contentinfo" className="footer">
        <div className="footer__top-wrapper">
          <section className="footer__top">
            <div className="footer__links-list">
              <div className="footer__links-list-title">About</div>
              <nav className="footer__links-list-nav">
                <div className="footer__links-list-item">
                  <a href="https://ine.com/about" className="" target="_blank">
                    About us
                  </a>
                  {/**/}
                </div>
                <div className="footer__links-list-item">
                  <a href="https://careers.ine.com" className="" target="_blank">
                    Careers
                  </a>
                  {/**/}
                </div>
                <div className="footer__links-list-item">
                  <a href="https://ine.com/platform" className="" target="_blank">
                    Our Platform
                  </a>
                  {/**/}
                </div>
                <div className="footer__links-list-item">
                  <a href="https://community.ine.com" className="" target="_blank">
                    Community
                  </a>
                  {/**/}
                </div>
                <div className="footer__links-list-item">
                  {/**/}
                  <a target="_blank" rel="noopener" href="https://ine.com/ine-live">
                    INE Live
                  </a>
                </div>
                <div className="footer__links-list-item" target="_blank" rel="noopener">
                  <a href="https://ine.com/newsroom" className="">
                    Newsroom
                  </a>
                </div>
                <div className="footer__links-list-item">
                  {/**/}
                  <a target="_blank" rel="noopener" href="https://ine.com/blog">
                    Blog
                  </a>
                </div>
              </nav>
            </div>
            <div className="footer__links-list">
              <div className="footer__links-list-title">Learning</div>
              <nav className="footer__links-list-nav">
                <div className="footer__links-list-item">
                  <a target="_blank" rel="noopener" href="https://ine.com/learning/courses">
                    Learning area
                  </a>
                </div>
                <div className="footer__links-list-item">
                  <a target="_blank" rel="noopener" href="https://ine.com/learning/paths">
                    Learning paths
                  </a>
                </div>
                <div className="footer__links-list-item">
                  <a target="_blank" rel="noopener" href="https://my.ine.com/bootcamps">
                    Courses &amp; Bootcamps
                  </a>
                </div>
                <div className="footer__links-list-item">
                  <a target="_blank" rel="noopener" href="https://ine.com/learning/instructors">
                    Instructors
                  </a>
                </div>
              </nav>
            </div>
            <div className="footer__links-list">
              <div className="footer__links-list-title">Plans</div>
              <nav className="footer__links-list-nav">
                <div className="footer__links-list-item">
                  {/**/}
                  <a target="_blank" rel="noopener" href="https://ine.com/pricing">
                    Plans &amp; Pricing
                  </a>
                </div>
                <div className="footer__links-list-item">
                  {/**/}
                  <a target="_blank" rel="noopener" href="https://ine.com/business-solutions">
                    Business Solutions
                  </a>
                </div>
              </nav>
            </div>
            <div className="footer__links-list">
              <div className="footer__links-list-title">Support</div>
              <nav className="footer__links-list-nav">
                <div className="footer__links-list-item">
                  {/**/}
                  <a target="_blank" rel="noopener" href="https://releases.ine.com">
                    Releases
                  </a>
                </div>
                <div className="footer__links-list-item">
                  {/**/}
                  <a target="_blank" rel="noopener" href="https://ine.com/faqs">
                    Help Center
                  </a>
                </div>
                <div className="footer__links-list-item">
                  {/**/}
                  <a target="_blank" rel="noopener" href="https://ine.com/contact-us">
                    Contact
                  </a>
                </div>
              </nav>
            </div>
            <div className="footer__community-section">
              <div className="styles_footerForm__u791a">
                <form className="styles_form__HFPN1" onSubmit={handleSubmit}>
                  <h2 className="text text--h5 styles_formTitle___q9Ei">
                    Hey! Don't miss anything - subscribe to our newsletter!
                  </h2>
                  <div className="styles_field__TEDnw styles_formField__R0BFJ">
                    <label
                      htmlFor="email"
                      className="text text--label-small styles_fieldLabel__k3gwi"
                    >
                      EMAIL
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="styles_fieldInput__U1hTD bg-transparent specific-input"
                      value={email}
                      placeholder="johnsnow@email.com"
                      aria-required="true"
                      aria-label="Email"
                      onChange={handleInputChange}
                    />
                  </div>
                  <button
                    className="btn btn--primary footer__community-button-cta"
                    disabled={!email || subscribed}
                    type="submit"
                  >
                    {subscribed ? "Subscribed" : "Subscribe"}
                  </button>
                </form>
              </div>
            </div>
            {/* <div className="footer__community-section">
              <div className="styles_footerForm__u791a">
                <form className="styles_form__HFPN1">
                  <h2 className="text text--h5 styles_formTitle___q9Ei">
                    Hey! Don't miss anything - subscribe to our newsletter!
                  </h2>
                  <div className="styles_field__TEDnw styles_formField__R0BFJ">
                    <label
                      htmlFor="email"
                      className="text text--label-small styles_fieldLabel__k3gwi"
                    >
                      EMAIL
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="styles_fieldInput__U1hTD bg-transparent specific-input"
                      defaultValue=""
                      placeholder="johnsnow@email.com"
                      aria-required="true"
                      aria-label="Email"
                    />
                  </div>
                  <button className="btn btn--primary footer__community-button-cta" disabled="" type="submit" onClick={sub}>
                    Subscribe
                  </button>
                </form>
              </div>
            </div> */}
          </section>
        </div>
        <div className="footer__bottom-wrapper">
          <section className="footer__bottom">
            <div className="footer__all-rights-reserved">
              © 2024 INE. All Rights Reserved. All logos, trademarks and registered trademarks are
              the property of their respective owners.
            </div>
            <div className="footer__links-policies">
              <a
                target="_blank"
                rel="noopener nofollow"
                href="https://ine.com/terms-of-service"
                className="footer__links-policies-item"
              >
                Terms of service
              </a>
              <a
                target="_blank"
                rel="noopener nofollow"
                href="https://checkout.shopify.com/17217507/policies/16723845.html"
                className="footer__links-policies-item"
              >
                Privacy Policy
              </a>
            </div>
            <div className="footer__links-social">
              <a
                target="_blank"
                rel="noopener nofollow"
                href="https://www.facebook.com/inetraining/"
                className="footer__links-social-item"
              >
                <svg
                  width={17}
                  height={16}
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="footer__links-social-item--facebook"
                >
                  <path
                    d="M16.5 8c0-4.4-3.6-8-8-8s-8 3.6-8 8c0 4 2.9 7.3 6.7 7.9v-5.6h-2V8h2V6.2c0-2 1.2-3.1 3-3.1.9 0 1.8.2 1.8.2v2h-1c-1 0-1.3.6-1.3 1.2V8h2.2l-.4 2.3H9.6V16c4-.6 6.9-4 6.9-8z"
                    fill="#fff"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener nofollow"
                href="https://twitter.com/ine"
                className="footer__links-social-item"
              >
                <svg
                  width={17}
                  height={14}
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="footer__links-social-item--twitter"
                >
                  <path
                    d="M16.5 2c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-2.1 0-3.7 2-3.2 4-2.7-.1-5.1-1.4-6.8-3.4-.9 1.5-.4 3.4 1 4.4-.5 0-1-.2-1.5-.4C1 6.6 2.1 8 3.6 8.4c-.5.1-1 .2-1.5.1.4 1.3 1.6 2.3 3.1 2.3-1.2.9-3 1.4-4.7 1.2 1.5.9 3.2 1.5 5 1.5 6.1 0 9.5-5.1 9.3-9.8.7-.4 1.3-1 1.7-1.7z"
                    fill="#fff"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener nofollow"
                href="https://www.linkedin.com/company/inetraining"
                className="footer__links-social-item"
              >
                <svg
                  width={17}
                  height={16}
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="footer__links-social-item--linkedin"
                >
                  <path
                    d="M4.1 16H.7V5.3h3.4V16zM2.4 3.8C1.3 3.8.5 3 .5 1.9.5.8 1.4 0 2.4 0c1.1 0 1.9.8 1.9 1.9 0 1.1-.8 1.9-1.9 1.9zM16.5 16h-3.4v-5.8c0-1.7-.7-2.2-1.7-2.2s-2 .8-2 2.3V16H6V5.3h3.2v1.5c.3-.7 1.5-1.8 3.2-1.8 1.9 0 3.9 1.1 3.9 4.4V16h.2z"
                    fill="#fff"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener nofollow"
                href="https://www.youtube.com/user/INEtraining"
                className="footer__links-social-item"
              >
                <svg
                  width={17}
                  height={12}
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="footer__links-social-item--youtube"
                >
                  <path
                    d="M16.1 2.1c-.2-.7-.7-1.2-1.4-1.4C13.5.4 8.4.4 8.4.4s-5 0-6.3.3C1.4.9.9 1.4.7 2.1.5 3.4.5 6 .5 6s0 2.6.3 3.9c.2.7.7 1.2 1.4 1.4 1.2.3 6.3.3 6.3.3s5 0 6.3-.3c.7-.2 1.2-.7 1.4-1.4.3-1.3.3-3.9.3-3.9s0-2.6-.4-3.9zM6.9 8.4V3.6L11.1 6 6.9 8.4z"
                    fill="#fff"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener nofollow"
                href="https://www.instagram.com/inetraining/"
                className="footer__links-social-item"
              >
                <svg
                  width={17}
                  height={18}
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="footer__links-social-item--instagram"
                >
                  <path
                    d="M8.5 2.2h3.4c.8 0 1.2.2 1.5.3.4.2.7.3 1 .6.3.3.5.6.6 1 .1.3.2.7.3 1.5v6.8c0 .8-.2 1.2-.3 1.5-.2.4-.3.7-.6 1-.3.3-.6.5-1 .6-.3.1-.7.2-1.5.3H5.1c-.8 0-1.2-.2-1.5-.3-.4-.2-.7-.3-1-.6-.3-.3-.5-.6-.6-1-.1-.3-.2-.7-.3-1.5V9 5.6c0-.8.2-1.2.3-1.5.2-.4.3-.7.6-1 .3-.3.6-.5 1-.6.3-.1.7-.2 1.5-.3h3.4zm0-1.5H5.1c-.9 0-1.5.2-2 .4s-1 .5-1.5 1-.7.9-1 1.5c-.2.5-.3 1.1-.4 2v6.8c0 .9.2 1.5.4 2s.5 1 1 1.5.9.7 1.5 1c.5.2 1.1.3 2 .4h6.8c.9 0 1.5-.2 2-.4s1-.5 1.5-1 .7-.9 1-1.5c.2-.5.3-1.1.4-2V9 5.6c0-.9-.2-1.5-.4-2s-.5-1-1-1.5-.9-.7-1.5-1c-.5-.2-1.1-.3-2-.4H8.5z"
                    fill="#fff"
                  />
                  <path
                    d="M8.5 4.7C6.1 4.7 4.2 6.6 4.2 9s1.9 4.3 4.3 4.3 4.3-1.9 4.3-4.3-1.9-4.3-4.3-4.3zm0 7.1C7 11.8 5.7 10.6 5.7 9c0-1.5 1.2-2.8 2.8-2.8 1.5 0 2.8 1.2 2.8 2.8 0 1.5-1.3 2.8-2.8 2.8zM12.9 5.6a1 1 0 100-2 1 1 0 000 2z"
                    fill="#fff"
                  />
                </svg>
              </a>
            </div>
          </section>
        </div>
      </footer>
    </>
  );
}

export default IneFooter;
