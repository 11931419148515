import { useState, useEffect } from "react";
import "./style.css";
function IneNotification({ message, status, open }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      open(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [open]);
  return (
    <>
      <div className="notifications">
        <div
          data-message-type="error"
          className="message"
          style={{ background: status === "error" ? "#860606" : "" }}
        >
          <div className="message__inner">
            <p className="message__text">{message}</p>
            <svg
              onClick={() => open(false)}
              viewBox="0 0 14 15"
              width={12}
              height={12}
              fill="none"
              stroke="#fff"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="message__close"
            >
              <path d="M13 1.5l-12 12M1 1.5l12 12" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}

export default IneNotification;
