import { useState, useEffect } from "react";
import axiosInstance from "utils/axios";
import { SSO_CLIENT_ID } from "config";
import { CAPTCHA_KEY } from "config";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../firebase";
import ReCAPTCHA from "react-google-recaptcha";

function ForgotPassword({ history, redirectUrl, setLoading, setSection, links, setNotificationMsg, open, setStatus }) {
  const [email, setEmail] = useState("");

  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleCaptchaVerify = (token) => {
    axiosInstance
      .post("/verify/recaptcha", { token })
      .then((res) => {
        setRecaptchaVerified(res.data.success);
      })
      .catch((err) => {
        //console.log(err);
      });

  };

  const login = async () => {
    google.accounts.id.initialize({
      client_id: SSO_CLIENT_ID,
      callback: (response) => {
        setLoading(true);
        const accessToken = response.credential;
        //console.log(accessToken);
        axiosInstance
          .post("/signup", { credential: accessToken })
          .then((res) => {
            //console.log(res.data.user.token);
            localStorage.setItem("token", res.data.user.token);
            setLoading(false);
            history.push(redirectUrl || "/home");
          })
          .catch((err) => {
            //console.log(err);
            setLoading(false);
            history.push("/home");
          });
      },
    });
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        document.cookie = "g_state=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
    });
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    try {
      await sendPasswordResetEmail(auth, email);
      //console.log("Password reset email sent successfully");
      setNotificationMsg(`Password reset email sent successfully to ${email}.`);
      setStatus("success");
      open(true);
      setEmail("");
      setButtonDisabled(false);
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        //console.log("User not found. Please check your email and try again.");
        setNotificationMsg("User not found. Please check your email and try again.");
      } else if (error.code === "auth/invalid-email") {
        //console.log("Invalid email. Please enter a valid email address.");
        setNotificationMsg("Invalid email. Please enter a valid email address.");
      } else {
        //console.log(error);
        setNotificationMsg("Something went wrong. Please try again.");
      }
      setStatus("error");
      open(true);
      setEmail("");
      setButtonDisabled(false);
    }
  };

  return (
    <>
      <div className="auth-form__wrapper">
        <div className="auth-form__forms">
          <div className="ine-form__form auth-form__form auth-form__form">
            <form className="ine-form__form auth-form__form">
              <div data-v-424874a5="" className="ine-form__title-wrapper auth-form__title-wrapper">
                <h1 data-v-424874a5="" className="ine-form__title">
                  Reset Your Password
                </h1>
                <p data-v-424874a5="" className="ine-form__tagline">
                  We will email instructions on how to reset your password
                </p>
                {/**/}
              </div>
              <div className="auth-form__form-group">
                <input
                  type="email"
                  placeholder="Your Email"
                  autoComplete="email"
                  className="auth-form__form-control ine-input"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              {/**/}
              <div className="auth-form__actions-group">
                <div className="ine-form__submit-btn-wrapper auth-form__actions-submit-wrapper">
                  <button
                    type="submit"
                    disabled={!email || !recaptchaVerified || buttonDisabled}
                    formNoValidate="formnovalidate"
                    className="btn btn--primary auth-form__actions__submit"
                    onClick={handlePasswordReset}
                  >
                    {/**/}
                    Submit
                  </button>
                </div>
                <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={handleCaptchaVerify} theme="dark"/>
              </div>
            </form>
          </div>
          <div className="auth-form__form-footer">
            <p className="ine-form__link-wrapper--lead">Don't Have an Account?</p>
            <a
              onClick={() => setSection("signup")}
              className="ine-link ine-form__link--signup"
              style={{ cursor: "pointer" }}
            >
              Create an account
            </a>
          </div>
        </div>
        <div className="ine-form__additional-links auth-form__form-footer-links">
          {links.map((link) => (
            <a
              key={link.href}
              href={link.href}
              target={link.target}
              rel={link.rel}
              className="ine-form__additional-link"
            >
              {link.text}
            </a>
          ))}
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
