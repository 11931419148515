import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------
const axiosLoginInstance = axios.create({
  baseURL: HOST_API,
});

axiosLoginInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.token}`;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/sign-in?session=c4bfb2a0bab0e91bc7dcfbe3bbec246e';
    }
    const er = {...error.response.data, status: error.response.status};
    return Promise.reject((error.response && error.response.data && er) || 'Something went wrong');
  }
);
export default axiosInstance;
export { axiosLoginInstance };