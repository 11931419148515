import jwtDecode from 'jwt-decode';
import axios from './axios';

// ----------------------------------------------------------------------

// const isValidToken = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }
//   const decoded = jwtDecode(accessToken);

//   const currentTime = Date.now() / 1000;

//   if (!decoded.exp) {
//     return false;
//   }

//   return decoded.exp > currentTime;
// };

const isValidToken = (accessToken) => {
  try {
    if (!accessToken) {
      return false;
    }
    
    const decoded = jwtDecode(accessToken);

    if (!decoded || typeof decoded !== 'object') {
      return false;
    }

    return true;
  } catch (error) {
    // console.error(`Error while decoding JWT token: ${error.message}`);
    return false;
  }
};

const handleTokenExpired = (exp) => {
  let expiredTimer;
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  clearTimeout(expiredTimer);
  expiredTimer = setTimeout(() => {
    localStorage.clear();
    window.location.href = '/sign-in?session=c4bfb2a0bab0e91bc7dcfbe3bbec246e';
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };

