import { useState, useEffect } from "react";
import labImage from "../../assets/images/IneSection/gns3-lab.png";
import { useParams, useHistory, Link } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import IneSpinner from "components/IneSpinner";
import IneHeader from "components/IneHeader";
import "./style.css";
import IneLabHeading from "components/IneLabHeading";
import IneModal from "components/IneModal";
import IneFooter from "components/IneFooter";

function Path() {
  const { categoryId } = useParams();
  const [categoriesData, setCategoriesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({ title: "", description: "" });
  const [checkoutUrl, setCheckoutUrl] = useState("https://ine.com/pricing");
  const history = useHistory();
  useEffect(() => {
    axiosInstance
      .get(`/categories/${categoryId}`)
      .then((res) => {
        //console.log(res.data);
        setCategoriesData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        history.push("/404");
        //console.log(err);
        setLoading(false);
      });
  }, []);

  const navigateToLab = (labId, played, availableLabRuns, checkoutUrl) => {
    history.push(`/${categoryId}/lab/${labId}`);
    // if (played) {
    //   setModalMessage({
    //     title: "Lab already completed",
    //     description:
    //       "We appreciate your interest in our lab! However, it appears that you have already completed this lab.",
    //   });
    //   setCheckoutUrl(checkoutUrl);
    //   setShowModal(true);
    // } else if (availableLabRuns <= 0){
    //   setModalMessage({
    //     title: "No More Lab Plays Available",
    //     description:
    //       "You have exhausted all your lab plays. To continue accessing the labs, please check our pricing and consider purchasing a subscription.",
    //   });
    //   setCheckoutUrl(checkoutUrl);
    //   setShowModal(true);
    // } else {

    // }
  };

  const resumeLab = (labId) => {
    history.push(`/${categoryId}/lab/${labId}`);
  };

  const LabComponent = ({
    id,
    labName,
    plan,
    played,
    availableLabRuns,
    currentRunningLab,
    billingPlanUrl,
  }) => {
    return (
      <div className="level lab unstarted">
        <div className="level">
          <div className="level__wrapper level__wrapper--enabled">
            {played ? (
              <>
                <div data-v-0fcc1517="" className="level-row__icon-wrapper">
                  <span data-v-0fcc1517="">
                    <svg
                      data-v-0fcc1517=""
                      width={20}
                      height={14}
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className=""
                    >
                      <path
                        data-v-0fcc1517=""
                        d="M18 1.5l-11 11-5-5"
                        stroke="#70FFD4"
                        strokeWidth={3}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </>
            ) : (
              <>
                <div data-v-0fcc1517="" className="level-row__icon-wrapper">
                  <span data-v-0fcc1517="">
                    <svg
                      data-v-0fcc1517=""
                      width={20}
                      height={14}
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="tickIcon"
                    >
                      <path data-v-0fcc1517="" d="M18 1.5l-11 11-5-5" />
                    </svg>
                  </span>
                </div>
              </>
            )}

            <div className="level__details-wrapper">
              <button
                className="action action__trigger level-row__action level-row__action--lab has-tooltip"
                data-original-title="null"
              >
                {/**/}
                {/**/}
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="level-row__action-icon"
                >
                  <path
                    d="M7.5 1h6.998M1 19h20l-8-9.46V1H9v8.54L1 19z"
                    stroke="#AFB0BF"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              <div className="level__title-wrapper" labtype="pta">
                <p className="level__title">
                  <span>{labName}</span>
                  <label className="level__title__label">
                    Billing Plan:{" "}
                    <a href={billingPlanUrl} target="blank">
                      {plan}
                    </a>
                  </label>
                </p>
              </div>
              {/* Other components */}
              <div className="level__resume">
                {String(currentRunningLab) === String(id)}
                {currentRunningLab === id.toString() ? (
                  <button
                    onClick={() => resumeLab(id)}
                    id="state-control"
                    className="btn btn--secondary level__btn-sub"
                  >
                    Resume lab
                  </button>
                ) : (
                  <button
                    onClick={() => navigateToLab(id, played, availableLabRuns, billingPlanUrl)}
                    id="state-control"
                    className="btn btn--secondary level__btn-sub"
                  >
                    Start lab
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const LabBlurComponent = ({ id, labName, stateMsg, plan }) => {
    return (
      <div className="level lab unstarted">
        <div className="level inactive">
          <div className="level__wrapper level__wrapper--enabled">
            <div data-v-0fcc1517="" className="level-row__icon-wrapper">
              <span data-v-0fcc1517="">
                <svg
                  data-v-0fcc1517=""
                  width={20}
                  height={14}
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="tickIcon"
                >
                  <path data-v-0fcc1517="" d="M18 1.5l-11 11-5-5" />
                </svg>
              </span>
            </div>

            <div className="level__details-wrapper">
              <button
                className="action action__trigger level-row__action level-row__action--lab has-tooltip"
                data-original-title="null"
              >
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="level-row__action-icon"
                >
                  <path
                    d="M7.5 1h6.998M1 19h20l-8-9.46V1H9v8.54L1 19z"
                    stroke="#AFB0BF"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              <div className="level__title-wrapper" labtype="pta">
                <p className="level__title">
                  <span>{labName}</span>
                  <label className="level__title__label">
                    Billing Plan: <a>{plan}</a>
                  </label>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="inactive_overlay">
          <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm.5-13H11v6l5.2 3.2l.8-1.3l-4.5-2.7V7z"
            />
          </svg>

          <h2 className="blurStateMessage">{stateMsg}</h2>
        </div>
      </div>
    );
  };

  return (
    <>
      <IneHeader setLoading={setLoading} />

      {loading ? (
        <IneSpinner />
      ) : (
        <>
          <main className="path_bg_image">
            <div
              className="container"
              style={{ paddingBottom: "15px", paddingTop: "50px", minHeight: "85vh" }}
            >
              <section className="course">
                <div className="course__content">{/**/}</div>
                <article
                // className="styles_backgroundDark__4X432"
                // style={{
                //   backgroundImage: `linear-gradient(rgb(0 0 0 / 93%) 0%, rgb(0 0 0 / 95%) 60%, rgb(21 20 20) 100%), url(${categoriesData.background})`,
                // }}
                >
                  <div className="course__overview-wrapper">
                    {/* breadcrumps */}
                    <ul className="breadcrumb_list">
                      <li className="li-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          className="bread-icon"
                        >
                          <path fill="currentColor" d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8z" />
                        </svg>
                        <a className="bread-link" href="/home">
                          Home
                        </a>
                      </li>
                      <li className="active-line">
                        <a className="bread-link" >
                          {categoriesData.title}
                        </a>
                      </li>
                    </ul>
                    {/* breadcrumps */}
                    <div className="overview">
                      <div className="overview__course-info-wrapper">
                        <div className="overview__branding-wrapper"></div>
                        <h4 itemProp="courseCode" className="overview__title content-title">
                          {categoriesData.title}
                        </h4>
                        <div className="overview__description-wrapper">
                          <p itemProp="about" className="overview__description content-description">
                            {categoriesData.description}
                          </p>
                          {/**/}
                        </div>
                      </div>
                      {/* GIFs */}

                      <div className="overview__video gif__wrapper">
                        <div className="sample-video">
                          <img className="overview-gif" src={categoriesData.gif} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="course__content-wrapper padjust">
                    <IneLabHeading title={categoriesData.category} icon={categoriesData.icon} />
                  </div>
                </article>
                <div className="course__content-wrapper">
                  <div className="course__group-wrapper">
                    <div className="group">
                      {/* <h5  class="group__title">Starting with FortiGate</h5> */}
                      <section className="group__topic-wrapper">
                        <div className="group__topic">
                          <div id="2-introduction-to-fortigate" className="topic">
                            <aside className="topic__aside">
                              <div className="topic__card">
                                <span>
                                  <div className="topic__details">
                                    <div className="topic__title">Available Labs</div>
                                    <div className="topic__notes-wrapper">{/**/}</div>
                                  </div>
                                  <p className="topic__detail">
                                    <span className="topic__lead">Activities:</span>
                                    <span className="topic__data">
                                      {categoriesData.labs.length}
                                    </span>
                                  </p>
                                </span>
                              </div>
                            </aside>
                          </div>
                          <article className="group__level-wrapper topic__level-wrapper">
                            {/* List the labs */}
                            {categoriesData.labs.map((lab) =>
                              lab.state === "active" ? (
                                <LabComponent
                                  key={lab.labid}
                                  id={lab.labid}
                                  labName={lab.labname}
                                  categoryId={categoryId}
                                  labState={lab.state}
                                  stateMsg={lab.stateMessage}
                                  plan={lab.billingPlan}
                                  played={lab.played}
                                  billingPlanUrl={lab.billingPlanUrl}
                                  availableLabRuns={categoriesData.availableLabRuns}
                                  currentRunningLab={categoriesData.currentRunningLab}
                                />
                              ) : (
                                <LabBlurComponent
                                  key={lab.labid}
                                  id={lab.labid}
                                  labName={lab.labname}
                                  categoryId={categoryId}
                                  labState={lab.state}
                                  stateMsg={lab.stateMessage}
                                  plan={lab.billingPlan}
                                  played={lab.played}
                                />
                              )
                            )}
                          </article>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <IneFooter />
          </main>
        </>
      )}
      {showModal && (
        <>
          <IneModal
            category={categoryId}
            messageTitle={modalMessage.title}
            messageDescription={modalMessage.description}
            pricingBtn={true}
            homeBtn={false}
            checkoutUrl={checkoutUrl}
            selfClose={true}
            selfCloseCallback={setShowModal}
          />
        </>
      )}
    </>
  );
}

export default Path;
