import { useState, useEffect } from "react";
import { isValidToken } from "utils/jwt";
import { useHistory } from "react-router-dom";
function IneHeader({ setLoading }) {
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(isValidToken(token));
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        setName(user.name);
        setEmail(user.email);
      } catch (error) {
        //console.log(error);
      }
    }
  }, [isLoggedIn]);

  const history = useHistory();

  const handleSignInClick = () => {
    history.push("/sign-in");
  };

  const handleLogout = () => {
    setLoading(true);
    setOpen(false);
    setDisableButton(true);
    setTimeout(() => {
      localStorage.clear();
      setIsLoggedIn(false);
      setLoading(false);
      setDisableButton(false);
      history.push("/home");
    }, 1000);
  };

  return (
    <>
      <header role="navigation" className="navbar navbar-app">
        <nav className="navbar__wrapper">
          <a href="/home" className="router-link-active">
            <img
              src="https://my.ine.com/img/home-logo.7d34f2b1.png"
              alt="INE Logo"
              className="navbar__ine-logo"
            />
          </a>
          <div className="showcase_text">Lab Showcase</div>

          <div className="navbar__menu"></div>
          <div className="profile navbar__user-profile">
            {/* <div className="profile__authenticate">
              <button
                className="profile__login-btn btn btn--secondary btn--small"
                onClick={() => history.push("/home")}
                style={{marginLeft:'15px'}}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} viewBox="0 0 36 36">
                  <path
                    fill="currentColor"
                    d="M33 19a1 1 0 0 1-.71-.29L18 4.41L3.71 18.71A1 1 0 0 1 2.3 17.3l15-15a1 1 0 0 1 1.41 0l15 15A1 1 0 0 1 33 19Z"
                    className="clr-i-solid clr-i-solid-path-1"
                  />
                  <path
                    fill="currentColor"
                    d="M18 7.79L6 19.83V32a2 2 0 0 0 2 2h7V24h6v10h7a2 2 0 0 0 2-2V19.76Z"
                    className="clr-i-solid clr-i-solid-path-2"
                  />
                  <path fill="none" d="M0 0h36v36H0z" style={{fill:'none !important'}}/>
                </svg>
                Home
              </button>
            </div> */}
            {!isLoggedIn && (
              <div className="profile__authenticate">
                <button
                  className="profile__login-btn btn btn--secondary btn--small"
                  onClick={handleSignInClick}
                  disabled={disableButton}
                >
                  Sign in
                </button>
              </div>
            )}

            {isLoggedIn && (
              <div className="profile__toggle-wrapper">
                {/*  */}
                {open ? (
                  <>
                    <div>
                      <div className="profile-menu__arrow" />
                      <div className="profile-menu">
                        <div className="profile-menu__header">
                          <div className="profile-menu__user-wrapper">
                            <div className="profile-progress profile-menu__profile-progress">
                              <button type="button" className="profile-progress__toggle-btn">
                                <img
                                  src="https://profiles.assets.ine.com/defaults/ifP3P96m1Nto5QY2JgcUWW1KdbfRrR0EeTknDZ0c.png"
                                  alt="Icon representing the user"
                                  className="profile-progress__image"
                                />
                              </button>
                            </div>
                            {isLoggedIn && (
                              <div className="profile-menu__user-info">
                                <p className="profile-menu__email">{email}</p>
                                <p className="profile-menu__name">{name}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="profile-menu__separator" />
                        <div className="profile-menu__list-wrapper">
                          <ul className="profile-menu__list">
                            <li className="profile-menu__list-item">
                              {isLoggedIn && (
                                <button
                                  className="btn btn--promo profile-menu__option profile-menu__option--signout"
                                  onClick={handleLogout}
                                  disabled={disableButton}
                                >
                                  Sign out
                                </button>
                              )}
                              {!isLoggedIn && (
                                // <Link to="/login" className="btn btn--promo profile-menu__option profile-menu__option--signout">
                                <button
                                  className="btn btn--promo profile-menu__option profile-menu__option--signout"
                                  onClick={handleSignInClick}
                                  disabled={disableButton}
                                >
                                  Sign In
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {/*  */}
                <div className="profile__info">
                  {isLoggedIn && <div className="profile__info-fullname">{name}</div>}
                  {/* <div className="profile__info-fullname">Sherin Stephen</div> */}
                  {/* <div className="profile__info-progress">Profile: 25% complete</div> */}
                  <div className="profile__info-business"></div>
                </div>
                <div className="profile-progress profile__progress">
                  <div></div>
                  <button
                    type="button"
                    className="profile-progress__toggle-btn"
                    onClick={handleOpen}
                  >
                    <img
                      src="https://profiles.assets.ine.com/defaults/ifP3P96m1Nto5QY2JgcUWW1KdbfRrR0EeTknDZ0c.png"
                      alt="Icon representing the user"
                      className="profile-progress__image"
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </nav>
      </header>

      <header role="navigation" className="mobile-navbar navbar-app">
        <nav className="mobile-navbar__wrapper">
          <div className="mobile-navbar">
            <div className="mobile-navbar__logo-wrapper">
              <a href="/home" className="router-link-active">
                <img
                  src="https://my.ine.com/img/home-logo.7d34f2b1.png"
                  alt="INE Logo"
                  className="mobile-navbar__ine-logo"
                />
              </a>
              <div className="showcase_text">Lab Showcase</div>
            </div>
            <div className="mobile-navbar__loginbtn-wrapper">
              {!isLoggedIn && (
                // <Link to="/login" className="btn btn--promo profile-menu__option profile-menu__option--signout">
                <button
                  className="profile__login-btn btn btn--secondary btn--small"
                  onClick={handleSignInClick}
                  disabled={disableButton}
                >
                  Sign In
                </button>
              )}
              {isLoggedIn && (
                <button
                  className="profile__login-btn btn btn--secondary btn--small"
                  onClick={handleLogout}
                  disabled={disableButton}
                >
                  Sign out
                </button>
              )}
              {/* {isLoggedIn && <div className="profile__info-fullname">{name}</div>} */}
            </div>
          </div>
          <ul className="mobile-menu" style={{ display: "none" }}>
            <div className="mobile-menu__arrow-container">
              <div className="mobile-menu__arrow" />
            </div>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default IneHeader;
