import React, { useState, useEffect, useRef } from "react";
import FOG from "vanta/dist/vanta.fog.min";
import "./style.css";

const Base = () => {
  const [vantaEffect1, setVantaEffect1] = useState(null);
  const [vantaEffect2, setVantaEffect2] = useState(null);
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);

  useEffect(() => {
    if (!vantaEffect1) {
      setVantaEffect1(
        FOG({
          el: myRef1.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: 0x770cf0,
          midtoneColor: 0x8c1616,
          lowlightColor: 0x13f05b,
          baseColor: 0x0,
          blurFactor: 0.54,
          speed: 0.0,
          zoom: 0.4,
        })
      );
    }

    if (!vantaEffect2) {
      setVantaEffect2(
        FOG({
          el: myRef2.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: 0x430274,
          midtoneColor: 0x8c1616,
          lowlightColor: 0x13f05b,
          baseColor: 0x0,
          blurFactor: 0.54,
          speed: 0.0,
          zoom: 0.4,
        })
      );
    }

    return () => {
      if (vantaEffect1) vantaEffect1.destroy();
      if (vantaEffect2) vantaEffect2.destroy();
    };
  }, [vantaEffect1, vantaEffect2]);

  return (
    <>
      <div className="hexagon-container" ref={myRef1}>
        <div className="left-inner">
          <div>
            <h6 class="custom-heading">
              Lab <br />
              <span>Showcase</span>
              {/* <span>.</span> */}
            </h6>
          </div>
          <div class="custom-container-2">
            <p class="custom-paragraph-2">
              At INE, we help our customers master technical skills in Cybersecurity, Networking,
              and Cloud by offering high-quality courses and 3100+ no-VPN, browser-based labs. Sign
              in now and try labs from many of our courses for FREE! No credit card required.
            </p>
          </div>
          <div className="base-btn-container">
            <a className="fancy" href="/home">
              <span className="top-key" />
              <span className="text">Continue</span>
              <span className="bottom-key-1" />
              <span className="bottom-key-2" />
            </a>
          </div>
        </div>
      </div>
      <div className="hexagon-container2" ref={myRef2}>
        <div className="right-inner">
          <div>
            <h6 class="custom-heading">
              Capture
              <br />
              <span>The Flag</span>
              {/* <span>.</span> */}
            </h6>
          </div>
          <div class="custom-container-2">
            <p class="custom-paragraph-2">
              The INE Capture the Flag (CTF) Arena is an immersive and dynamic cybersecurity
              platform designed to challenge and enhance the skills of cybersecurity professionals,
              enthusiasts, and students. Offering a wide range of realistic and engaging scenarios
              across diverse environments.
            </p>
          </div>
          <div className="base-btn-container">
            <a className="fancy" href="/ctf/home">
              <span className="top-key" />
              <span className="text">Continue</span>
              <span className="bottom-key-1" />
              <span className="bottom-key-2" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Base;
