// react-router-dom components
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// Vision UI Dashboard React components
import { Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/system";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import { keyframes } from "@emotion/react";

function IneLabListCard({ image, label, title, description, action, authors }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClicked ) {
        setIsClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isClicked]);

  const CloseButton = styled(({ className, ...props }) => (
    <IconButton className={className} {...props}>
      <Box
        component="span"
        sx={{
          display: "inline-block",
          width: "1rem",
          height: "1rem",
          position: "relative",
        }}
      >
        <Box
          component="span"
          sx={{
            display: "block",
            width: "100%",
            height: "2px",
            backgroundColor: "currentColor",
            position: "absolute",
            top: "50%",
            left: "0",
            transform: "rotate(45deg) translateY(-50%)",
          }}
        />
        <Box
          component="span"
          sx={{
            display: "block",
            width: "100%",
            height: "2px",
            backgroundColor: "currentColor",
            position: "absolute",
            top: "50%",
            left: "0",
            transform: "rotate(-45deg) translateY(-50%)",
          }}
        />
      </Box>
    </IconButton>
  ))({
    color: "rgba(255, 255, 255, 0.8)",
    padding: 0,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    "&:active": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  });
  const growAnimation = keyframes`
  from {
    width: 0;
    height: 0;
    opacity: 0;
  }

  to {
    width: 80vw;
    height: 80vh;
    opacity: 1;
  }
`;

const CourseCard = styled(Card)(({ theme, image }) => ({
  flex: "0 0 auto",
  height: "100%",
  marginRight: "16px",
  background: `url(${image})`,
  height: "100%",
  // backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(0,0,0,0.8) 100%), url(${image})`,
  backgroundSize: "cover",
  color: "#fff",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: "10px 0px 0px 10px",
  position: "relative",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    cursor: "pointer",
    // boxShadow: `0 0 10px ${theme.palette.primary.main}`,
  },
  // Media query
  [theme.breakpoints.down("md")]: {
    height: '300px',
  },
  [theme.breakpoints.down("xs")]: {
    width: '100%',
  },
  ...(isClicked && {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    height: "70vh",
    zIndex: "10",
    animation: `${growAnimation} 0.3s ease-in-out`,
    backgroundSize: "contain",
    backdropFilter: "none",
    backgroundColor: "none",
    boxShadow: "none",
    [theme.breakpoints.down("xl")]: {
      height: "70vh",
    },
  }),
}));

  const CourseTitle = styled(Typography)({
    marginBottom: "8px",
    fontSize: "18px",
    color: "#fff",
    fontFamily:"CircularStd-Medium"
  });

  const CoursePlay = styled(Typography)({
    fontSize: "14px",
    position: "absolute",
    left: "20px",
  });

  const CourseDescription = styled(Typography)({
    fontSize: "14px",
    position: "absolute",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    background: "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0))",
    padding: "8px 16px",
    borderRadius: "4px",
    color: "#fff",
  });

  const CourseLevel = styled(Typography)({
    fontSize: "14px",
    position: "absolute",
    bottom: "20px",
    right: "20px",
  });

  const CourseCardContent = styled(CardContent)({
    padding: "0px",
  });

  const CourseInfo = styled(CardContent)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  });

  return (
    <VuiBox sx={{ position: "relative" }}>
      {/* <Card> */}
      <div className="session-lab-card">
      <div className="home-lab-card__wrapper">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={4} xl={3}>
          <CourseCard image={image} onClick={handleClick} />
          </Grid>
          <Grid item xs={12} md={7} lg={8} xl={9}>
            <Grid container spacing={2} direction="column" pt={3} pb={3}>
              <Grid item >
                <CourseTitle>{title}</CourseTitle>
              </Grid>
              <Grid item >
                <VuiTypography variant="button" fontWeight="regular" color="text" dangerouslySetInnerHTML={{ __html: description }} sx={{ fontSize: '16px'}} >
                  
                </VuiTypography>
              </Grid>
              <Grid item >
                {/* <VuiButton
                  component={Link}
                  to={action.route}
                  variant="outlined"
                  size="large"
                  sx={{ color: "#EF5D11", borderColor: "#EF5D11",height: "42px" }}
                >
                  {action.label}
                </VuiButton> */}
                <a id="state-control" className="btn btn--secondary home__btn-sub" href={action.route}>Try now</a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </div>
        </div>
      {/* </Card> */}
    </VuiBox>
  );
}

// Setting default values for the props of IneLabListCard
IneLabListCard.defaultProps = {
  authors: [],
};

// Typechecking props for the IneLabListCard
IneLabListCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "white",
      "text",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default IneLabListCard;
