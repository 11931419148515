import { useState, useEffect } from "react";
import axiosInstance from "utils/axios";
import { SSO_CLIENT_ID } from "config";
import { CAPTCHA_KEY } from "config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import useWoopraTracker from "utils/woopraTracker";

function IneSignIn({
  history,
  redirectUrl,
  setLoading,
  setSection,
  links,
  setNotificationMsg,
  open,
  setStatus,
  GoogleLogin,
  AuthLogin,
}) {
  const { trackWoopraEvent } = useWoopraTracker();
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleCaptchaVerify = (token) => {
    setRecaptchaToken(token);
  };

  const login = async () => {
    google.accounts.id.initialize({
      client_id: SSO_CLIENT_ID,
      callback: (response) => {
        setLoading(true);
        const accessToken = response.credential;
        //console.log(accessToken);
        axiosInstance
          .post("/signup", { credential: accessToken })
          .then((res) => {
            //console.log(res.data.user.token);
            localStorage.setItem("token", res.data.user.token);
            setLoading(false);
            history.push(redirectUrl || "/home");
          })
          .catch((err) => {
            //console.log(err);
            setLoading(false);
            history.push("/home");
          });
      },
    });

    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        document.cookie = "g_state=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
    });
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    open(false);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      AuthLogin(userCredential.user.accessToken, "password", recaptchaToken);
      setEmail("");
      setPassword("");
      setButtonDisabled(false);
      trackWoopraEvent({
        form_name: 'login',
        email: email,
      });
    } catch (error) {
      setEmail("");
      setPassword("");
      setButtonDisabled(false);
      switch (error.code) {
        case "auth/invalid-email":
          setNotificationMsg("Please enter a valid email address.");
          setStatus("error");
          open(true);
          break;
        case "auth/user-not-found":
          setNotificationMsg("User not found. Please check your email and try again.");
          setStatus("error");
          open(true);
          break;
        case "auth/wrong-password":
          setNotificationMsg("Incorrect credentials. Please try again.");
          setStatus("error");
          open(true);
          break;
        default:
          //console.log("An unknown error occurred. Please try again later.");
          setNotificationMsg("An unknown error occurred. Please try again later.");
          setStatus("error");
          open(true);
          break;
      }
    }
  };

  // Add reCAPTCHA script to the page (v3)
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://www.google.com/recaptcha/api.js?render=6LeulysmAAAAAMqfq1uspesXdwZfyQeuflijclin';
  //   script.async = true;
  //   script.defer = true;
  //   document.body.appendChild(script);

  //   script.onload = () => {
  //     setIsRecaptchaLoaded(true);
  //   };

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  // const verifyCaptcha = (e) => {
  //   e.preventDefault();
  //   if (isRecaptchaLoaded) {
  //     window.grecaptcha.ready(() => {
  //       window.grecaptcha.execute('6LeulysmAAAAAMqfq1uspesXdwZfyQeuflijclin', { action: 'submit' }).then((token) => {
  //         // Add your logic to submit to your backend server here.
  //         //console.log(token);

  //       });
  //     });
  //   }
  // };

  return (
    <>
      <div className="auth-form__wrapper">
        <div className="auth-form__wrapper--centered">
          <div className="auth-form__forms auth-form__forms-login">
            <div
              className="auth-form__form-wrapper"
              recaptchasitekey="6Lfh96oaAAAAAFCVq4EoTG_2RlH5i6PhbLK9cFkH"
            >
              <div className="ine-form__form auth-form__form">
                <div className="ine-form__title-wrapper auth-form__title-wrapper">
                  <h1 className="ine-form__title title--no-tagline">Sign in to INE Labs</h1>
                  <p className="ine-form__tagline" />
                </div>
                <div className="auth-form__providers-wrapper">
                    <button
                      className="btn btn--secondary btn--default-icon-color"
                      onClick={GoogleLogin}
                    >
                      <svg width={24} height={24} viewBox="0 0 24 24" className="">
                        <path
                          d="M5.876 14.295l-.766 2.859-2.8.059A10.95 10.95 0 011 12c0-1.824.444-3.544 1.23-5.059l2.492.457 1.092 2.477A6.538 6.538 0 005.46 12c0 .807.146 1.581.415 2.295z"
                          fill="#FBBB00"
                        />
                        <path
                          d="M22.808 9.945c.126.665.192 1.353.192 2.055a10.997 10.997 0 01-4.113 8.578h-.001l-3.139-.16-.444-2.774a6.555 6.555 0 002.82-3.347h-5.881V9.945H22.808z"
                          fill="#518EF8"
                        />
                        <path
                          d="M18.886 20.577v.001A10.954 10.954 0 0112 23c-4.189 0-7.831-2.342-9.689-5.787l3.565-2.918a6.54 6.54 0 009.427 3.35l3.583 2.932z"
                          fill="#28B446"
                        />
                        <path
                          d="M19.021 3.532L15.458 6.45a6.542 6.542 0 00-9.644 3.425L2.231 6.94H2.23A10.998 10.998 0 0112 1c2.669 0 5.116.95 7.021 2.532z"
                          fill="#F14336"
                        />
                      </svg>
                      Log In with Google
                    </button>
                  </div>
                  <div className="auth-form__oauth">
                    <span className="auth-form__oauth-separator">OR</span>
                  </div>
                <div className="auth-form__form-group">
                  <div className="auth-form__input-wrapper">
                    <input
                      placeholder="Email"
                      autoComplete="email"
                      type="email"
                      className="auth-form__form-control ine-input"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="auth-form__input-wrapper">
                    <input
                      placeholder="Password"
                      autoComplete="current-password"
                      type="password"
                      className="auth-form__form-control ine-input"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </div>

                <div className="auth-form__actions">
                  <div className="auth-form__actions-recaptcha-wrapper">
                    <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={handleCaptchaVerify} theme="dark"/>
                  </div>
                </div>

                <div className="auth-form__actions">
                  <div className="auth-form__actions-group">
                    <div className="ine-form__submit-btn-wrapper auth-form__actions-submit-wrapper auth-form__actions-submit-wrapper--login">
                      <button
                        type="submit"
                        disabled={!email || !password || !recaptchaToken || buttonDisabled}
                        formNoValidate="formnovalidate"
                        className="btn btn--primary auth-form__actions__submit"
                        onClick={handleSignIn}
                      >
                        Sign In
                      </button>
                    </div>

                    <a onClick={() => setSection("forgot-password")} className="">
                      <button className="btn btn--tertiary">Forgot your password?</button>
                    </a>
                  </div>
                  <div className="auth-form__form-footer">
                    <p className="ine-form__link-wrapper--lead">Don't Have an Account?</p>
                    <a
                      onClick={() => setSection("signup")}
                      className="ine-link ine-form__link--signup"
                      style={{ cursor: "pointer" }}
                    >
                      Create an account
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="ine-form__additional-links auth-form__form-footer-links">
              {links.map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  target={link.target}
                  rel={link.rel}
                  className="ine-form__additional-link"
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="auth-form__captcha-wrapper" />
      </div>
    </>
  );
}

export default IneSignIn;
