import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { isValidToken } from "utils/jwt";

const CheckTokenExpiration = () => {
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem("token");
      //     //console.log('code : working');
      //   if (!token || !isValidToken(token)) {
      //     // Token not found in localStorage
      //     history.push("/sign-in");
      //     clearInterval(interval);
      //     return;
      //   }

      if (!token || !isValidToken(token)) {
        return;
      }
      try {
        const decoded = jwtDecode(token);
        const now = Date.now() / 1000; // convert to Unix timestamp in seconds

        if (decoded.exp < now) {
          // Token has expired
          localStorage.clear();
          history.push("/sign-in?session=c4bfb2a0bab0e91bc7dcfbe3bbec246e");
          clearInterval(interval);
          return;
        }
      } catch (error) {
        localStorage.clear();
        history.push("/sign-in?session=c4bfb2a0bab0e91bc7dcfbe3bbec246e");
        clearInterval(interval);
        return;
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [history]);

  return null;
};

export default CheckTokenExpiration;
