import SignIn from "layouts/sign-in";
import Home from "layouts/home";
import Base from "layouts/base";
import Lab from "layouts/lab";
import Path from "layouts/path";
import NotFound from "layouts/notFound";


const routes = [
  {
    key: "base",
    route: "/",
    component: Base,
    auth: false,
  },
  {
    key: "home",
    route: "/home",
    component: Home,
    auth: false,
  },
  {
    key: "404",
    route: "/404",
    component: NotFound,
    auth: false,
  },
  {
    key: "sign-in",
    route: "/sign-in",
    component: SignIn,
    auth: false,
  },
  {
    key: "lab",
    route: "/:categoryId/lab/:id",
    component: Lab,
    auth: true,
  },
  {
    key: "labs",
    route: "/labs/:categoryId",
    component: Path,
    auth: false,
  }
];

export default routes;
