import { useState, useEffect } from "react";
import axiosInstance from "utils/axios";
import { SSO_CLIENT_ID } from "config";
import { CAPTCHA_KEY } from "config";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import useWoopraTracker from "utils/woopraTracker";

function IneSignUp({
  history,
  redirectUrl,
  setLoading,
  setSection,
  links,
  setNotificationMsg,
  open,
  setStatus,
  GoogleLogin,
}) {
  const { trackWoopraEvent } = useWoopraTracker();
  // const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleCaptchaVerify = (token) => {
    // setIsCaptchaVerified(true);
    //console.log(token);
    setRecaptchaToken(token);
    axiosInstance
      .post("/verify/recaptcha", { token })
      .then((res) => {
        //console.log(res.data.success);
        setRecaptchaVerified(res.data.success);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const validateFullName = (fullName) => {
    const pattern = /^[a-zA-ZÀ-ÿ]+(([',. -][a-zA-ZÀ-ÿ ])?[a-zA-ZÀ-ÿ]*)*$/;
    const isValidFullName = pattern.test(fullName) && fullName.length <= 50;
    return isValidFullName;
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    if (!recaptchaVerified) {
      setNotificationMsg("Please verify that you are not a robot.");
      setStatus("error");
      open(true);
      setEmail("");
      setPassword("");
      setFullName("");
      setButtonDisabled(false);
      return;
    }
    if (!validateFullName(fullName)) {
      setNotificationMsg(
        "Please enter a valid name. Only 50 characters are allowed. Only alphabetic characters, apostrophes, commas, periods, hyphens, and spaces are allowed."
      );
      setStatus("error");
      open(true);
      setEmail("");
      setPassword("");
      setFullName("");
      setButtonDisabled(false);
      return;
    }
    try {
      //console.log("email", email);
      //console.log("password", password);
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // console.log('IneSignUp userCredential', userCredential);
          //console.log(userCredential.user.accessToken);

          // Set display name
          updateProfile(auth.currentUser, {
            displayName: fullName,
          })
            .then(() => {
              trackWoopraEvent({
                form_name: "sign up",
                name: fullName,
                email: email,
              });
              // console.log("Display name updated");
            })
            .catch((error) => {
              //console.log(error);
            });

          // Send email verification
          sendEmailVerification(auth.currentUser)
            .then(() => {
              // console.log("Email verification sent");
              setNotificationMsg(
                `An email has been sent to ${email}. Please follow the instructions in the email to verify your account.`
              );
              setStatus("success");
              open(true);
            })
            .catch((error) => {
              //console.log(error);
            });
          // console.log('IneSignUp userCredential.user', userCredential.user);
          setEmail("");
          setPassword("");
          setFullName("");
          setButtonDisabled(false);
        })
        .catch((error) => {
          //console.log(error);
          if (error.code === "auth/email-already-in-use") {
            setNotificationMsg("This email is already in use.");
          } else if (error.code === "auth/invalid-email") {
            setNotificationMsg("Invalid email address.");
          } else if (error.code === "auth/weak-password") {
            setNotificationMsg("Password should be at least 6 characters.");
          } else {
            setNotificationMsg("Something went wrong. Please try again later.");
          }
          setStatus("error");
          open(true);
          setEmail("");
          setPassword("");
          setFullName("");
          setButtonDisabled(false);
        });
    } catch (error) {
      console.error(error);
      setNotificationMsg("Something went wrong. Please try again later.");
      setStatus("error");
      open(true);
      setEmail("");
      setPassword("");
      setFullName("");
      setButtonDisabled(false);
    }
  };

  return (
    <>
      <div className="auth-form__wrapper">
        <div className="auth-form__wrapper--centered">
          <div className="auth-form__forms auth-form__forms-login">
            <div className="auth-form__form-wrapper">
              <div className="ine-form__form auth-form__form">
                <div className="ine-form__title-wrapper auth-form__title-wrapper">
                  <h1 className="ine-form__title title--no-tagline">Create an account</h1>
                  <p className="ine-form__tagline" />
                </div>
                <div className="auth-form__providers-wrapper">
                  <button
                    className="btn btn--secondary btn--default-icon-color"
                    onClick={GoogleLogin}
                  >
                    <svg width={24} height={24} viewBox="0 0 24 24" className="">
                      <path
                        d="M5.876 14.295l-.766 2.859-2.8.059A10.95 10.95 0 011 12c0-1.824.444-3.544 1.23-5.059l2.492.457 1.092 2.477A6.538 6.538 0 005.46 12c0 .807.146 1.581.415 2.295z"
                        fill="#FBBB00"
                      />
                      <path
                        d="M22.808 9.945c.126.665.192 1.353.192 2.055a10.997 10.997 0 01-4.113 8.578h-.001l-3.139-.16-.444-2.774a6.555 6.555 0 002.82-3.347h-5.881V9.945H22.808z"
                        fill="#518EF8"
                      />
                      <path
                        d="M18.886 20.577v.001A10.954 10.954 0 0112 23c-4.189 0-7.831-2.342-9.689-5.787l3.565-2.918a6.54 6.54 0 009.427 3.35l3.583 2.932z"
                        fill="#28B446"
                      />
                      <path
                        d="M19.021 3.532L15.458 6.45a6.542 6.542 0 00-9.644 3.425L2.231 6.94H2.23A10.998 10.998 0 0112 1c2.669 0 5.116.95 7.021 2.532z"
                        fill="#F14336"
                      />
                    </svg>
                    Log In with Google
                  </button>
                </div>
                <div className="auth-form__oauth">
                  <span className="auth-form__oauth-separator">OR</span>
                </div>

                <div className="auth-form__form-group">
                  <div className="auth-form__input-wrapper">
                    <input
                      placeholder="Full Name"
                      autoComplete="name"
                      type="text"
                      className="auth-form__form-control ine-input"
                      value={fullName}
                      onChange={(event) => setFullName(event.target.value)}
                    />
                  </div>
                  <div className="auth-form__input-wrapper">
                    <input
                      placeholder="Email"
                      autoComplete="email"
                      type="email"
                      className="auth-form__form-control ine-input"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="auth-form__input-wrapper">
                    <input
                      placeholder="Password"
                      autoComplete="current-password"
                      type="password"
                      className="auth-form__form-control ine-input"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </div>
                <div style={{ color: 'white', textAlign: 'justify', marginTop: '30px' }}>
                  By signing up, I am agreeing to my information being stored by INE and to receive
                  marketing outreach from INE and its partner companies as outlined in{" "}
                  <a href="https://ine.com/privacy-policy" target="_blank" style={{ color: "orange" }}>
                    INE's Privacy Policy
                  </a>
                  . I can revoke my consent at any time by unsubscribing using the link included in
                  each email received or by sending an email to{" "}
                  <a href="mailto:support@ine.com" style={{ color: "orange" }}>
                    support@ine.com
                  </a>
                  .
                </div>
                <div className="auth-form__actions">
                  <div className="auth-form__actions-group">
                    <div className="ine-form__submit-btn-wrapper auth-form__actions-submit-wrapper auth-form__actions-submit-wrapper--login">
                      <button
                        type="submit"
                        disabled={
                          !email || !password || !fullName || !recaptchaVerified || buttonDisabled
                        }
                        formNoValidate="formnovalidate"
                        className="btn btn--primary auth-form__actions__submit"
                        onClick={handleSignUp}
                      >
                        Sign Up
                      </button>
                    </div>
                    <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={handleCaptchaVerify} theme="dark" />
                  </div>
                  {/* <div className="auth-form__actions-group"></div> */}
                  <div className="auth-form__form-footer">
                    <p className="ine-form__link-wrapper--lead">Have an Account?</p>
                    <a
                      onClick={() => setSection("signin")}
                      className="ine-link ine-form__link--signup"
                      style={{ cursor: "pointer" }}
                    >
                      Login to the account
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="ine-form__additional-links auth-form__form-footer-links">
              {links.map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  target={link.target}
                  rel={link.rel}
                  className="ine-form__additional-link"
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="auth-form__captcha-wrapper" />
      </div>
    </>
  );
}

export default IneSignUp;
