import { useCallback } from 'react';

const useWoopraTracker = () => {

  const getQueryParam = useCallback((paramName) => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(paramName);
    }
    return null;
  }, []);

  const trackWoopraEvent = useCallback((trackingData) => {
    if (typeof window !== 'undefined' && window.woopra) {
      const fullTrackingData = {
        ...trackingData,
        page_url: window.location.href,
        utm_source: getQueryParam('utm_source'),
        utm_medium: getQueryParam('utm_medium'),
        utm_campaign: getQueryParam('utm_campaign'),
        utm_content: getQueryParam('utm_content'),
        utm_term: getQueryParam('utm_term'),
        utm_adgroup: getQueryParam('utm_adgroup'),
        utm_network: getQueryParam('utm_network'),
        utm_placement: getQueryParam('utm_placement'),
        gclid: getQueryParam('gclid'),
        fbclid: getQueryParam('fbclid'),
      };

      const identificationData = {
        email: trackingData.email,
        name: trackingData.name || undefined,
      };

      window.woopra.identify(identificationData);
      window.woopra.track(trackingData.form_name, fullTrackingData);
    //   console.log('Woopra event tracked:', trackingData.form_name, fullTrackingData);
    }
  }, [getQueryParam]);

  return { trackWoopraEvent };
};

export default useWoopraTracker;