import React from "react";
import labImage from "../../assets/images/icons/CyberSecurity.png";
function IneLabHeading(props) {
 //console.log(props);
  return (
    <>
      <div className="course-carousel__link">
        <img src={props.icon} className="title_icon_secondary" alt="Lab Icon"/>
        <h3 className="course-carousel__link-title">{props.title}</h3>
        {/* <div className="course-carousel__view-all">
          Browse all
          <span className="material-icons course-carousel__more-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="custom-class feather feather-chevrons-right"
            >
              <polyline points="13 17 18 12 13 7" />
              <polyline points="6 17 11 12 6 7" />
            </svg>
          </span>
        </div> */}
      </div>
    </>
  );
}

export default IneLabHeading;
